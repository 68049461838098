import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import IndividualRow from "./IndividualRow";
import { useEffect } from "react";
import settingsActions from "../redux/actions/settingsActions";
import candidatesActions from "../redux/actions/candidatesActions";
import LoadingAnimationTable from "./LoadingAnimationTable";
import ExcelDownload from "./ExcelExport";

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createTheme } from '@material-ui/core'


export const customTheme = createTheme({
    overrides: {
        MuiPickersDay: {
            day: {
                color: "black !important",
                fontFamily: "\"Do Hyeon\", sans-serif",
                backgroundColor: "white",
            },
            
            container:{
                backgroundColor:"black"
            },
            daySelected: {
                backgroundColor: "#0100FB !important",
                color:"white !important"
            },
            dayDisabled: {
                color: "light-gray",
            },
            current: {
                color: "#0100FB !important",
            },
        },
        MuiInput: {
            root: 'white',
            underline: {
                borderBottom: '0.5px solid white'
            }
        },
        MuiFormLabel: {
            root: {
                color: 'white'
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                color: 'black !important'
            }
        },
        MuiInputBase: {
            color: 'white',
            label: {
                color: 'white',
            },
            root: {
                color: 'white !important'
            },
            input: {
              color: 'white',
              colorSecondary: 'white'
            },
        },
        MuiTableFooter: {
          input: {
            color: 'black !important'
          }
        }
    },
})

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "firstname",
    numeric: false,
    disablePadding: true,
    label: "Nombre"
  },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "form_date_responded", numeric: false, disablePadding: false, label: "Fecha Form" },
  { id: "candidate_evalart_status", numeric: false, disablePadding: false, label: "Tipo" },
  { id: "test_status", numeric: false, disablePadding: false, label: "Status" },
  { id: "total_score", numeric: true, disablePadding: false, label: "Resultado" },
  { id: "verbal_aptitude", numeric: true, disablePadding: false, label: "AV" },
  { id: "numerical_aptitude", numeric: true, disablePadding: false, label: "AN" },
  { id: "logical_thinking", numeric: true, disablePadding: false, label: "RL" },
  { id: "spatial_reasoning", numeric: true, disablePadding: false, label: "IE" },
  { id: "weighted_score", numeric: true, disablePadding: false, label: "Ponderación" },
  { id: "admision", numeric: false, disablePadding: false, label: "Calificación" }
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.headerTable}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: 'auto',
    overflowY: 'hidden'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: 'white',
    borderRadius: '5px',
  },
  headerTable:{
    backgroundColor: '#0000559f',
    color:'white'
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,

  },
  container: {
    borderRadius: '3px'
  },
  table_row: {
    borderBottom: 'none',
    textDecoration: 'none',
    width: '100%'
  },
  table_cell:{
  width:'100%'
  }
}));

const Tables = (props) => {
  const { candidatesFiltered, getScores, getResultsTest, candidates, getResultsRange, token, filterCandidates, startDate, endDate, name, status } = props
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  useEffect(() => getScores(token), [])
  useEffect(() => getResultsRange(token))

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, candidatesFiltered?.length - page * rowsPerPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const handleChangeRows = () => {
    if (emptyRows >= rowsPerPage){
      setPage(0)
    }
  }

  if (candidates?.length === 0 || candidatesFiltered?.length === 0) {
    getResultsTest('B2C', token)
    return <LoadingAnimationTable />
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={candidatesFiltered?.length}
            />
            <TableBody className={classes.tableBody}>
              {candidatesFiltered === undefined
                ? <TableRow className={classes.table_row}>
                  <TableCell className={classes.table_cell}>No hay candidatos para esta búsqueda.</TableCell>
                </TableRow>
                : stableSort(candidatesFiltered, getComparator(order, orderBy))?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((candidate, index) => <IndividualRow key={index} candidate={candidate} filterCandidates={filterCandidates} startDate={startDate} endDate={endDate} name={name} status={status}/>)
              }
            </TableBody>
            
          </Table>
        </TableContainer>
        <MuiThemeProvider theme={customTheme}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={candidatesFiltered !== undefined ? candidatesFiltered?.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onChange={handleChangeRows()}
        />
        </MuiThemeProvider>
      </Paper>
      <div style={{width:'15vh'}}>
        <ExcelDownload candidates={candidatesFiltered}/>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    candidates: state.candidatesReducer.candidates,
    scores: state.settingsReducer.scores,
    token: state.authReducer.userLogged.token
  }
}
const mapDispatchToProps = {
  getScores: settingsActions.getScores,
  getResultsTest: candidatesActions.getResultsTest,
  getResultsRange: settingsActions.getResultsRange

}

export default connect(mapStateToProps, mapDispatchToProps)(Tables)