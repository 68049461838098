import axios from "axios"
import API from "../../components/helpers/api"
import { showToastError500, showToast } from "../../components/helpers/toast"

const emailsActions = {
    getAllEmails: (token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.get(API + '/emails/get-all-emails', {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                dispatch({type: 'GET_ALL_EMAILS', payload: response.data})
            } catch (error) {
                console.log(error)
                showToastError500()    
            }
        }
    },
    getEmailById: (idParams, token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.get(API + '/emails/get-email/' + idParams, {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                dispatch({type: 'GET_EMAIL_BY_ID', payload: response.data[0]})
                return response.data[0]
            } catch (error) {
                console.log(error)
                showToastError500() 
            }
        }
    },
    updateContent: (emailContent, token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.put(API + '/emails/update-content', emailContent, {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                dispatch({type: 'GET_EMAIL_BY_ID', payload: response.data[0]})
                if (response?.status === 201) {
                    showToast("Contenido actualizado exitosamente.")
                }
                return response.data[0]
            } catch (error) {
                console.log(error)
                showToastError500() 
            }
        }
    },
    settingsUpdate: (settings, token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.put(API + '/emails/update-configs', settings, {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                if (response?.status === 201) {
                    showToast("Configuración actualizada exitosamente.")
                }
            } catch (error) {
                console.log(error)
                showToastError500() 
            }
        }
    }
}
export default emailsActions