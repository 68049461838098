import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Menu from "../components/Menu"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const EmailAreas = (props) => {
    const idParams = Number(props.match.params.id)

    const emailBootcamp = props?.emails?.filter(x => x?.email_id === idParams)[0]?.bootcamp

    return (
        <div style={{ backgroundImage: 'url("/assets/background.svg")' }} className="logInBackground">
            <div className="containerAdminControl">
                <Menu />
                <div style={{margin:'4vh 0 0 4vh'}}>
                <Breadcrumbs aria-label="breadcrumb" style={{color:'white'}}>
                  <Link to='/settings' color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                    Configuraciones
                  </Link>
                  <Link to='/bootcamps' color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                    Bootcamps
                  </Link>
                  <p color="inherit" style={{color:'white'}}>{emailBootcamp}</p>
                </Breadcrumbs>
                </div>
                <div className="containerSettings" >
                    <div className="containerSettingsInfo">
                        <h1 style={{marginBottom:'5vh', marginTop:'-0.2vh'}}>{emailBootcamp}</h1>
                        <div className="containerBootcamps">
                            <Link to={`/email_template/${idParams}`} className="linkAreas"><h2>Email Template</h2></Link>
                            <Link to={`/email_settings/${idParams}`} className="linkAreas"><h2>Email Configuraciones</h2></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return{
        emails: state.emailsReducer.emails
    }
}
export default connect(mapStateToProps)(EmailAreas)