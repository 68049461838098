import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { showToast } from "../components/helpers/toast"
import Menu from "../components/Menu"
import Ponderation from "../components/Ponderation"
import ScoreArea from "../components/ScoreArea"
import ScoreAreaMin from "../components/ScoreAreaMin"
import settingsActions from "../redux/actions/settingsActions"
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom"

const Area = (props) => {
    const { scores, updatePonderations,  updateMaxScoreEvalart, updateMinScorePonderation, token, getScores } = props
    const idParams = props.match.params.id
    const [area, setArea] = useState({})
    const { score_setting_name } = area

    useEffect(() => setArea(scores.find(element => String(element.score_setting_id) === idParams)), [])

    const updatePonderation = async (score_setting_id, newValue) => {
        if (newValue !== '' && newValue > 0 && newValue < 100) {
            await updatePonderations(score_setting_id, newValue, token)
            const response = await getScores(token)  
            const info = await response.find(element => String(element.score_setting_id) === idParams)
            setArea(info)
        } else {
            showToast('El valor es incorrecto, intente nuevamente')
        }
    }

    const updateMaxScore = async (score_setting_id, newValue) => {
        if (newValue !== '' && newValue > 0 && newValue < 370) {
            await updateMaxScoreEvalart(score_setting_id, newValue, token)
            const response = await getScores(token)  
            const info = await response.find(element => String(element.score_setting_id) === idParams)
            setArea(info)
        } else {
            showToast('El valor es incorrecto, intente nuevamente')
        }
    }

    const updateMinScore = async (score_setting_id, newValue) => {
        if (newValue !== '' && newValue >= 0 && newValue < 100) {
            await updateMinScorePonderation(score_setting_id, newValue, token)
            const response = await getScores(token)  
            const info = await response.find(element => String(element.score_setting_id) === idParams)
            setArea(info)
        } else {
            showToast('El valor es incorrecto, intente nuevamente')
        }
    }

    return (
        <>
        <div style={{ backgroundImage: 'url("/assets/background.svg")' }} className="logInBackground">
            <div className="containerAdminControl">
                <Menu />
                <div style={{margin:'4vh 0 0 4vh'}}>
                <Breadcrumbs aria-label="breadcrumb" style={{color:'white'}}>
                  <Link to='/settings' color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                    Configuraciones
                  </Link>
                  <Link to='/areas' color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                    Áreas
                  </Link>
                  <p color="inherit" style={{color:'white'}}>{score_setting_name}</p>
                </Breadcrumbs>
                </div>
                <div className="containerSettings">
                    <div className="containerSettingsInfo">
                        <h1>{score_setting_name}</h1>
                        <div className="containerScores">
                            <Ponderation area={area} updatePonderation={updatePonderation}/>
                            <ScoreArea area={area} updateMaxScore={updateMaxScore}/>
                            <ScoreAreaMin area={area} updateMinScore={updateMinScore} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
const mapStateToProps = state => {
    return {
        scores: state.settingsReducer.scores,
        token: state.authReducer.userLogged.token
    }
}
const mapDispatchToProps = {
    updatePonderations: settingsActions.updatePonderations,
    updateMaxScoreEvalart: settingsActions.updateMaxScoreEvalart,
    updateMinScorePonderation: settingsActions.updateMinScorePonderation,
    getScores: settingsActions.getScores,

}
export default connect(mapStateToProps, mapDispatchToProps)(Area)