import { useState } from "react"
import emailsActions from "../redux/actions/emailsActions"
import { connect } from "react-redux"

const EmailSubject = (props) => {
    const { email_id, email_subject, settingUpdate } = props
    const [newValue, setNewValue] = useState(email_subject)
    const [editSetting, setEditSetting] = useState(true)

    return (
        <div className="containerScore">
            <h2>Subject</h2>
            {editSetting &&
                <div className="containerSettingValue">
                    <span className="settingValue">{email_subject}</span>
                    <span onClick={() => setEditSetting(!editSetting)} className="material-icons-round iconEdit">edit</span>
                </div>
            }
            {!editSetting &&
                <div className="containerInputEdit">
                    <input className="inputEditSettings" type="text" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
                    <div className="containerButtonsEdit">
                        <span onClick={() => { settingUpdate(email_id, 'email_subject', newValue); setEditSetting(!editSetting) }} className="material-icons-round iconSendEdit">send</span>
                        <span onClick={() => setEditSetting(!editSetting)} className="material-icons-round iconEdit">close</span>
                    </div>
                </div>
            }
        </div>
    )
}

const mapDispatchToProps = {
    getEmailById: emailsActions.getEmailById,
    settingsUpdate: emailsActions.settingsUpdate
}

export default connect(null, mapDispatchToProps)(EmailSubject)