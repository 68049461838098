import {combineReducers} from 'redux'
import authReducer from './authReducer'
import candidatesReducer from './cantidatesReducer'
import settingsReducer from './settingsReducer'
import emailsReducer from './emailsReducer'

const rootReducer = combineReducers({
    authReducer: authReducer,
    candidatesReducer: candidatesReducer,
    settingsReducer: settingsReducer,
    emailsReducer: emailsReducer
})

export default rootReducer