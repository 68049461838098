import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import settingsActions from "../redux/actions/settingsActions";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";


const useStyles = makeStyles((theme) => ({
    table_row: {
        borderBottom: '0.5px solid #f5f5ef33',
        textDecoration: 'none',
    },
    approvedCandidate: {
        backgroundColor: 'yellow',
        textDecoration: 'none',
    },
    dubiousCandidate: {
        backgroundColor: 'yellow',
        textDecoration: 'none'
    }
}));

const IndividualRow = (props) => {
    const classes = useStyles();
    const { candidate, scores, results_range, token, startDate, endDate, name, filterCandidates, status } = props
    const { firstname, lastname, email, candidate_evalart_status, total_score, first_question_total, second_question_total
        , third_question_total, fourth_question_total, bootcamp, test_status, max_score, form_date_responded
    } = candidate
    const [candidateInfo, setCandidateInfo] = useState([])
    //result areas test evalart
    let resultTestAV = Number(first_question_total)
    let resultTestAN = Number(second_question_total)
    let resultTestRL = Number(third_question_total)
    let resultTestIE = Number(fourth_question_total)

    //result test evalart
    let resultTest = total_score && max_score && total_score + " / " + max_score

    //max_score evalart
    let maxScoreAV = Number(scores?.filter(score => score?.score_setting_name === "Aptitud Verbal")[0]?.max_score)
    let maxScoreAN = Number(scores?.filter(score => score?.score_setting_name === "Aptitud Numérica")[0]?.max_score)
    let maxScoreIE = Number(scores?.filter(score => score?.score_setting_name === "Inteligencia Espacial")[0]?.max_score)
    let maxScoreRL = Number(scores?.filter(score => score?.score_setting_name === "Razonamiento Lógico")[0]?.max_score)

    //areas
    let verbal_aptitude = resultTestAV && maxScoreAV && resultTestAV + " / " + maxScoreAV
    let numerical_aptitude = resultTestAN && maxScoreAN && resultTestAN + " / " + maxScoreAN
    let logical_thinking = resultTestRL && maxScoreRL && resultTestRL + " / " + maxScoreRL
    let spatial_reasoning = resultTestIE && maxScoreIE && resultTestIE + " / " + maxScoreIE

    //ponderation
    let decimalValue1 = '0.0'
    let decimalValue2 = '0.'

    let ponderationAV = parseFloat(decimalValue1.concat(scores?.filter(score => score?.score_setting_name === "Aptitud Verbal")[0]?.ponderation))
    let ponderationAN = parseFloat(decimalValue2.concat(scores?.filter(score => score?.score_setting_name === "Aptitud Numérica")[0]?.ponderation))
    let ponderationRL = parseFloat(decimalValue2.concat(scores?.filter(score => score?.score_setting_name === "Razonamiento Lógico")[0]?.ponderation))
    let ponderationIE = parseFloat(decimalValue1.concat(scores?.filter(score => score?.score_setting_name === "Inteligencia Espacial")[0]?.ponderation))

    //min_score_ponderation
    let minScorePonderationAV = Number(scores?.filter(score => score?.score_setting_name === "Aptitud Verbal")[0]?.max_score_ponderation)
    minScorePonderationAV = minScorePonderationAV * maxScoreAV / 100
    let minScorePonderationAN = Number(scores?.filter(score => score?.score_setting_name === "Aptitud Numérica")[0]?.max_score_ponderation)
    minScorePonderationAN = minScorePonderationAN * maxScoreAN / 100
    let minScorePonderationRL = Number(scores?.filter(score => score?.score_setting_name === "Razonamiento Lógico")[0]?.max_score_ponderation)
    minScorePonderationRL = minScorePonderationRL * maxScoreRL / 100
    let minScorePonderationIE = Number(scores?.filter(score => score?.score_setting_name === "Inteligencia Espacial")[0]?.max_score_ponderation)
    minScorePonderationIE = minScorePonderationIE * maxScoreIE / 100

    //score ponderation formula
    let weighted_score = (((resultTestAV * ponderationAV) / maxScoreAV) * 100)
        + (((resultTestAN * ponderationAN) / maxScoreAN) * 100)
        + (((resultTestRL * ponderationRL) / maxScoreRL) * 100)
        + (((resultTestIE * ponderationIE) / maxScoreIE) * 100)

    //aprobation range    
    let aprobadoMin = Number(results_range?.filter(range => range?.result_setting_name === "aprobado_min")[0]?.result_setting_value)
    let dudosoMin = Number(results_range?.filter(range => range?.result_setting_name === "dudoso_min")[0]?.result_setting_value)
    let dudosoMax = Number(results_range?.filter(range => range?.result_setting_name === "dudoso_max")[0]?.result_setting_value)

    //conditions
    let admision;
    let aprobacion;
    let classesCandidate;

    if (resultTestAN >= minScorePonderationAN && resultTestRL >= minScorePonderationRL) {
        admision = 'ADMITIDO'
        if (weighted_score > 0 && weighted_score > aprobadoMin) {
            aprobacion = 'APROBADO'
            classesCandidate = "approvedCandidate"
        } else if (weighted_score > 0 && weighted_score < aprobadoMin && weighted_score >= dudosoMin) {
            aprobacion = 'DUDOSO'
            classesCandidate = "dubiousCandidate"
        } else if (weighted_score > 0 && weighted_score < dudosoMin) {
            aprobacion = "DESAPROBADO"
            classesCandidate = "disapprovedCandidate"
        }
    } else {
        admision = "DESAPROBADO"
        classesCandidate = "disapprovedCandidate"
    }

    return (
        <TableRow component={Link} to={{ pathname: `/candidate/${candidate.candidate_id}`, state: { filterCandidates, startDate, endDate, name, status } }} className={classesCandidate}>
            <TableCell scope="row" padding="none" align="justify" className={classes.table_row}>{firstname} {lastname}</TableCell>
            <TableCell align="justify" className={classes.table_row}>{email}</TableCell>
            <TableCell align="justify" className={classes.table_row}>{form_date_responded}</TableCell>
            <TableCell align="justify" className={classes.table_row}>{candidate_evalart_status}</TableCell>
            <TableCell align="justify" className={classes.table_row}>{test_status}</TableCell>
            <TableCell align="justify" className={classes.table_row}>{resultTest}</TableCell>
            <TableCell align="justify" className={classes.table_row}>{verbal_aptitude}</TableCell>
            <TableCell align="justify" className={classes.table_row}>{numerical_aptitude}</TableCell>
            <TableCell align="justify" className={classes.table_row}>{logical_thinking}</TableCell>
            <TableCell align="justify" className={classes.table_row}>{spatial_reasoning}</TableCell>
            <TableCell align="center" className={classes.table_row}>{weighted_score.toFixed(2)}</TableCell>
            <TableCell align="center" className={classes.table_row}>{test_status === 'FIN'  ? (admision === 'DESAPROBADO' ? admision : aprobacion) : 'SIN HACER'}</TableCell>
        </TableRow>
    )
}

const mapStateToProps = state => {
    return {
        scores: state.settingsReducer.scores,
        results_range: state.settingsReducer.results_range,
        token: state.authReducer.userLogged.token
    }
}

const mapDispatchToProps = {
    getScores: settingsActions.getScores,
    getResultsRange: settingsActions.getResultsRange
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualRow)