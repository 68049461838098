import axios from 'axios'
import API from '../../components/helpers/api'
import { showToast, showToastError500 } from '../../components/helpers/toast'

const candidatesActions = {
    getResultsTest: (processName, token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.get(API + '/results/get-results/' + processName, {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                dispatch({ type: 'RESULTS_TEST', payload: response.data })
                return response.data
            } catch (error) {
                console.log(error)
                //showToastError500()
            }
        }
    },
    getCurrentCandidate: (candidateId, token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.get(API + '/results/get-user-results/' + candidateId, {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                return response.data.user
            } catch (error) {
                console.log(error)
                showToastError500()
            }
        }
    }
}
export default candidatesActions