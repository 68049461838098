import axios from 'axios'
import API from '../../components/helpers/api'
import { showToast, showToastError500 } from '../../components/helpers/toast'

const authActions = {
    logAdmin: (logInAdmin, history) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.post(API + "/users/login", logInAdmin)
                if (!response.data.success) {
                    showToast(response.data.message)
                } else {
                    dispatch({ type: 'LOG_ADMIN', payload: response.data})
                    showToast('Acceso a Proceso Admisión MH')
                }
            } catch (error) {
                console.log(error)
                showToastError500()
            }
        }
    },
    logOutAdmin: () => {
        return (dispatch, getState) => {
            dispatch({ type: 'LOGOUT_ADMIN' })
        }
    },
    logAdminLS: (userAdminLS) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.get(API + "/users/loginls", {
                    headers: {
                        'Authorization': 'Bearer ' + userAdminLS.token
                    }
                })
                dispatch({ type: 'LOG_ADMIN', payload: { ...response.data, token: userAdminLS.token } })
            } catch (error) {
                console.log(error)
                showToastError500()
            }
        }
    }
}
export default authActions