const initialState = {
    userLogged: null,
}

const authReducer = ( state = initialState, action) => {
    switch (action.type) {
        case 'LOG_ADMIN':
            localStorage.setItem('userLogged', JSON.stringify({username: action.payload.username,
                email: action.payload.email}))
            localStorage.setItem('token', action.payload.token)
            return {
                ...state,
                userLogged: action.payload
            }
        case 'LOGOUT_ADMIN':
            localStorage.clear()
            return {
                ...state,
                userLogged: null,
            }         
        default:
            return state
    }
}

export default authReducer