import { NavLink, Link } from 'react-router-dom'
import { FaRegUserCircle } from 'react-icons/fa'
import { connect } from 'react-redux'
import authActions from '../redux/actions/authActions'

const Menu = (props) => {
    const { userLogged } = props
    return (
        <div className="containerMenu">
            <div className="navLinksMenu">
                {userLogged &&
                    <>
                        <NavLink to="/home" className="link">
                            <span className="material-icons-round iconMenu">home</span>
                            <span className="linkName">Procesos</span>
                        </NavLink>

                        <NavLink to="/admincontrol" className="link">
                            <span className="material-icons-round iconMenu">dashboard</span>
                            <span className="linkName">Tablas</span>
                        </NavLink>

                        <NavLink to="/settings" className="link">
                            <span className="material-icons-round iconMenu">settings</span>
                            <span className="linkName">Configuraciones</span>
                        </NavLink>
                    </>
                }
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        userLogged: state.authReducer.userLogged,
        loading: state.authReducer.loading
    }
}
const mapDispatchToProps = {
    logOutAdmin: authActions.logOutAdmin
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu)