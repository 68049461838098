import { connect } from "react-redux"
import settingsActions from "../redux/actions/settingsActions"
import Menu from "../components/Menu"
import { Link } from "react-router-dom"
import LoadingAnimationTable from "../components/LoadingAnimationTable"
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const Areas = (props) => {
    const { scores, getScores, token } = props

    if (scores.length === 0) {
        getScores(token)
        return <LoadingAnimationTable />
    }

    return (
        <div style={{ backgroundImage: 'url("./assets/background.svg")' }} className="logInBackground">
            <div className="containerAdminControl">
                <Menu />
                <div style={{margin:'4vh 0 0 4vh'}}>
                <Breadcrumbs aria-label="breadcrumb" style={{color:'white'}}>
                  <Link to='/settings' color="inherit" style={{color: '#BAFD01', textDecoration: 'none'}}>
                    Configuraciones
                  </Link>
                  <p color="inherit" style={{color:'white'}}>Áreas</p>
                </Breadcrumbs>
                </div>
                <div className="containerSettings">
                    <div className="containerSettingsInfo">
                        <h1 style={{marginTop:'-0.2vh'}}>Áreas</h1>
                        <div className="containerAreasInfo">
                            {
                                scores?.success !== false && scores?.map((score, index) => {
                                    return (
                                        <Link key={index} to={`/area/${score.score_setting_id}`} className="linkAreas">
                                            <h2>{score.score_setting_name}</h2>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        scores: state.settingsReducer.scores,
        token: state.authReducer.userLogged.token
    }
}
const mapDispatchToProps = {
    getScores: settingsActions.getScores
}
export default connect(mapStateToProps, mapDispatchToProps)(Areas)