import { connect } from "react-redux"
import candidatesActions from "../redux/actions/candidatesActions";
import { Link } from "react-router-dom"
const Process = (props) => {
    const { getResultsTest, token } = props
    return (
        <div className="homeInfo">
            <Link to="/admincontrol" className="containerB2CLink">
                <div style={{ backgroundImage: "url('./assets/B2C_process.png')" }} className="b2cImage"></div>
                <span onClick={() => getResultsTest('B2C', token)}>B2C</span>
            </Link>
            <button disabled className="containerB2BLink">
                <div style={{ backgroundImage: "url('./assets/B2B_process.png')" }} className="b2bImage"></div>
                {/* <Link to="/admincontrol"> */}
                <span onClick={() => getResultsTest('B2B', token)}>B2B</span>
                {/* </Link> */}
            </button>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { 
        token: state.authReducer.userLogged.token
    }
}

const mapDispatchToProps = {
    getResultsTest: candidatesActions.getResultsTest
}

export default connect(null, mapDispatchToProps)(Process)