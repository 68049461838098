import React, { useState } from "react";
import ReactExport from "react-export-excel";
import { connect } from "react-redux";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExcelDownload = (props) => {
    
    const { scores, candidates, results_range } = props
    const allCandidates = []

    //result areas test evalart
    const mapCandidates = candidates?.map(candidate => {
        let resultTestAV = Number(candidate?.first_question_total)
        let resultTestAN = Number(candidate?.second_question_total)
        let resultTestRL = Number(candidate?.third_question_total)
        let resultTestIE = Number(candidate?.fourth_question_total)

            //max_score evalart
        let maxScoreAV = Number(scores?.filter(score => score?.score_setting_name === "Aptitud Verbal")[0]?.max_score)
        let maxScoreAN = Number(scores?.filter(score => score?.score_setting_name === "Aptitud Numérica")[0]?.max_score)
        let maxScoreIE = Number(scores?.filter(score => score?.score_setting_name === "Inteligencia Espacial")[0]?.max_score)
        let maxScoreRL = Number(scores?.filter(score => score?.score_setting_name === "Razonamiento Lógico")[0]?.max_score)

        //ponderation
        let decimalValue1 = '0.0'
        let decimalValue2 = '0.'

        let ponderationAV = parseFloat(decimalValue1.concat(scores?.filter(score => score?.score_setting_name === "Aptitud Verbal")[0]?.ponderation))
        let ponderationAN = parseFloat(decimalValue2.concat(scores?.filter(score => score?.score_setting_name === "Aptitud Numérica")[0]?.ponderation))
        let ponderationRL = parseFloat(decimalValue2.concat(scores?.filter(score => score?.score_setting_name === "Razonamiento Lógico")[0]?.ponderation))
        let ponderationIE = parseFloat(decimalValue1.concat(scores?.filter(score => score?.score_setting_name === "Inteligencia Espacial")[0]?.ponderation))

        //score ponderation formula
        var weighted_score = (((resultTestAV * ponderationAV) / maxScoreAV) * 100)
        + (((resultTestAN * ponderationAN) / maxScoreAN) * 100)
        + (((resultTestRL * ponderationRL) / maxScoreRL) * 100)
        + (((resultTestIE * ponderationIE) / maxScoreIE) * 100)

        let aprobadoMin = Number(results_range?.filter(range => range?.result_setting_name === "aprobado_min")[0]?.result_setting_value)
        let dudosoMin = Number(results_range?.filter(range => range?.result_setting_name === "dudoso_min")[0]?.result_setting_value)
    
        let minScorePonderationAN = Number(scores?.filter(score => score?.score_setting_name === "Aptitud Numérica")[0]?.max_score_ponderation)
        minScorePonderationAN = minScorePonderationAN * maxScoreAN / 100
        let minScorePonderationRL = Number(scores?.filter(score => score?.score_setting_name === "Razonamiento Lógico")[0]?.max_score_ponderation)
        minScorePonderationRL = minScorePonderationRL * maxScoreRL / 100

        //conditions
        let admision;
        let aprobacion;
        let classesCandidate;
    
        if (resultTestAN >= minScorePonderationAN && resultTestRL >= minScorePonderationRL) {
            admision = 'ADMITIDO'
            if (weighted_score > 0 && weighted_score > aprobadoMin) {
                aprobacion = 'APROBADO'
                classesCandidate = "approvedCandidate"
            } else if (weighted_score > 0 && weighted_score < aprobadoMin && weighted_score >= dudosoMin) {
                aprobacion = 'DUDOSO'
                classesCandidate = "dubiousCandidate"
            } else if (weighted_score > 0 && weighted_score < dudosoMin) {
                aprobacion = "DESAPROBADO"
                classesCandidate = "disapprovedCandidate"
            }
        } else {
            admision = "DESAPROBADO"
            classesCandidate = "disapprovedCandidate"
        }

        allCandidates.push(
                {
                firstname: candidate.firstname,
                lastname: candidate.lastname,
                email: candidate.email,
                form_date_responded: candidate.form_date_responded,
                test_status: candidate.test_status,
                candidate_evalart_status: candidate.candidate_evalart_status,
                max_score: candidate.max_score,
                total_score: candidate.total_score,
                first_question_total: candidate.first_question_total,
                first_question_max: candidate.first_question_max,
                second_question_total: candidate.second_question_total,
                second_question_max: candidate.second_question_max,
                third_question_total: candidate.third_question_total,
                third_question_max: candidate.third_question_max,
                fourth_question_total: candidate.fourth_question_total,
                fourth_question_max: candidate.fourth_question_max,
                weighted_score: weighted_score.toFixed(2),
                calification: candidate.test_status === 'FIN'  ? (admision === 'DESAPROBADO' ? admision : aprobacion) : 'SIN HACER'
                }
            )
    })

    return (
        <ExcelFile filename="reporte_candidatos_admision" element={<button className="buttonExport">EXPORTAR</button>}>
            <ExcelSheet data={allCandidates} name="Candidatos">
                <ExcelColumn label="Nombre" value="firstname"/>
                <ExcelColumn label="Apellido" value="lastname"/>
                <ExcelColumn label="Email" value="email"/>
                <ExcelColumn label="Tipo" value="candidate_evalart_status"/>
                <ExcelColumn label="Test Status" value="test_status"/>
                <ExcelColumn label="Puntaje total" value="total_score"/>
                <ExcelColumn label="Puntaje máximo" value="max_score"/>
                <ExcelColumn label="AV Total" value="first_question_total"/>
                <ExcelColumn label="AV Máximo" value="first_question_max"/>
                <ExcelColumn label="AN Total" value="second_question_total"/>
                <ExcelColumn label="AN Máximo" value="second_question_max"/>
                <ExcelColumn label="RL Total" value="third_question_total"/>
                <ExcelColumn label="RL Máximo" value="third_question_max"/>
                <ExcelColumn label="IE Total" value="fourth_question_total"/>
                <ExcelColumn label="IE Máximo" value="fourth_question_max"/>
                <ExcelColumn label="Puntaje ponderado" value="weighted_score"/>
                <ExcelColumn label="Calificación" value="calification"/>
            </ExcelSheet>
        </ExcelFile>
    );
}

const mapStateToProps = state => {
    return {
        scores: state.settingsReducer.scores,
        results_range: state.settingsReducer.results_range,
        token: state.authReducer.userLogged.token
    }
}

export default connect(mapStateToProps) (ExcelDownload)