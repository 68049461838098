import axios from "axios"
import API from "../../components/helpers/api"
import { showToastError500, showToast } from "../../components/helpers/toast"

const settingsActions = {
    getScores: (token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.get(API + "/settings/get-scores", {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                dispatch({ type: 'GET_SCORES', payload: response.data })
                return response.data
            } catch (error) {
                console.log(error)
                showToastError500()
            }
        }
    },
    updatePonderations: (score_setting_id, ponderation, token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.put(API + "/settings/update-ponderation", { score_setting_id, ponderation }, {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                if (response?.status === 201) {
                    showToast("Información actualizada exitosamente.")
                }
                return response.data
            } catch (error) {
                console.log(error)
                showToastError500()
            }
        }
    },
    updateMaxScoreEvalart: (score_setting_id, max_score, token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.put(API + "/settings/update-max-score", { score_setting_id, max_score },  {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                if (response?.status === 201) {
                    showToast("Información actualizada exitosamente.")
                }
                return response.data
            } catch (error) {
                console.log(error)
                showToastError500()
            }
        }
    },
    updateMinScorePonderation: (score_setting_id, max_score_ponderation, token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.put(API + "/settings/update-max-score-ponderation", { score_setting_id, max_score_ponderation }, {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                if (response?.status === 201) {
                    showToast("Información actualizada exitosamente.")
                }
                return response.data
            } catch (error) {
                console.log(error)
                showToastError500()
            }
        }
    },
    getResultsRange: (token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.get(API + "/settings/get-results-range", {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                dispatch({ type: 'GET_RESULTS_RANGE', payload: response.data })
            } catch (error) {
                console.log(error)
                showToastError500()
            }
        }
    },
    updateResultsRange: (result_setting_id, result_setting_value, token) => {
        return async (dispatch, getState) => {
            try {
                const response = await axios.put(API + "/settings/update-results-range", { result_setting_id, result_setting_value }, {
                    headers: {
                       Authorization: `Bearer ${token}`
                    }
                 })
                if (response?.status === 201) {
                    showToast("Información actualizada exitosamente.")
                }
                dispatch({type:'GET_RESULTS_RANGE', payload: response.data})
                return response.data
            } catch (error) {
                console.log(error)
                showToastError500()
            }
        }
    }
}
export default settingsActions