import { useState } from "react";

const Ponderation = (props) => {
    const { area, updatePonderation } = props
    const { ponderation, score_setting_id } = area
    const [editSetting, setEditSetting] = useState(true)
    const [newValue, setNewValue] = useState("")

    return (
        <div className="containerScore">
            <h2>Ponderación</h2>
            {editSetting &&
                <div className="containerSettingValue">
                    <span className="settingValue">{ponderation}%</span>
                    <span onClick={() => setEditSetting(!editSetting)} className="material-icons-round iconEdit">edit</span>
                </div>
            }
            {!editSetting &&
                <div className="containerInputEdit">
                    <input className="inputEditSettings" type="text" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
                    <div className="containerButtonsEdit">
                        <span onClick={() => { updatePonderation(score_setting_id, newValue); setEditSetting(!editSetting) }} className="material-icons-round iconSendEdit">send</span>
                        <span onClick={() => setEditSetting(!editSetting)} className="material-icons-round iconEdit">close</span>
                    </div>
                </div>
            }
        </div>
    )
}
export default Ponderation