import { connect } from "react-redux"
import { FaRegUserCircle } from 'react-icons/fa'
import { useState } from "react"
import authActions from "../redux/actions/authActions"

const Header = (props) => {
    const { userLogged, logOutAdmin } = props
    const { user } = userLogged
    const { username, email } = user
    const [userWindow, setUserWindow] = useState(false)
    const avatarDefault = userLogged
        ? <div style={{ backgroundImage: 'url("https://webdesing881317710.files.wordpress.com/2021/07/logomindhub2.jpg")' }} className="avatarImageHeader"></div>
        : <FaRegUserCircle className="iconsLateralMenu" />

    return (
        <div className="homeHeader">
            <div style={{ backgroundImage: 'url("./assets/logoMindhub.png")' }} className="headerLogo"></div>
            <div className="containerUserAvatar" onClick={() => { setUserWindow(!userWindow) }}>
                {avatarDefault}
                <span>{user && username}</span>
            </div>
            {userWindow &&
                <div className="containerUserProfile">
                    {avatarDefault}
                    <span>{username}</span>
                    <small>{email}</small>
                    <div onClick={logOutAdmin} className="logOut">CERRAR SESIÓN</div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userLogged: state.authReducer.userLogged
    }
}
const mapDispatchToProps = {
    logOutAdmin: authActions.logOutAdmin
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)