const initialState = {
    emails: [],
    currentEmail: {}
}

const emailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_EMAILS':
            return {
                ...state,
                emails: action.payload,
            }
        case 'GET_EMAIL_BY_ID':
            return {
                ...state,
                currentEmail: action.payload,
            }
        default:
            return state
    }
}
export default emailsReducer