import { connect } from "react-redux"
import Menu from "../components/Menu"
import Range from "../components/Range"
import settingsActions from "../redux/actions/settingsActions"
import { showToast } from "../components/helpers/toast"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom"

const Ranges = (props) => {
    const { results_range, getResultsRange, token, updateResultsRange } = props

    const updateRange = async (result_setting_id, newValue) => {
        if (newValue !== '' && newValue > 0 && newValue < 100) {
            await updateResultsRange(result_setting_id, newValue, token)
            const response = await getResultsRange(token)
        } else {
            showToast('El valor es incorrecto, intente nuevamente')
        }
    }

    return (
        <div style={{ backgroundImage: 'url("./assets/background.svg")' }} className="logInBackground">
            <div className="containerAdminControl">
                <Menu />
                <div style={{margin:'4vh 0 0 4vh'}}>
                <Breadcrumbs aria-label="breadcrumb" style={{color:'white'}}>
                  <Link to='/settings' color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                    Configuraciones
                  </Link>
                  <p color="inherit" style={{color:'white'}}>Rangos de aprobación</p>
                </Breadcrumbs>
                </div>
                <div className="containerSettings">
                    <div className="containerSettingsInfo">
                        <h1 style={{marginTop:'-0.2vh'}}>Rangos de aprobación</h1>
                        <span style={{marginTop: '-3vh', marginBottom: '2vh', textAlign:'center'}}>
                        *Recordá revisar que los rangos elegidos no se superpongan*  
                        </span>
                        <div className="containerScores">
                            {
                                results_range?.success !== false && results_range?.map((range, index) => <Range key={index} range={range} updateRange={updateRange}/>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.authReducer.userLogged.token,
        results_range: state.settingsReducer.results_range
    }
}

const mapDispatchToProps = {
    updateResultsRange: settingsActions.updateResultsRange,
    getResultsRange: settingsActions.getResultsRange
}

export default connect(mapStateToProps, mapDispatchToProps)(Ranges)