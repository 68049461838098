import { toast } from 'react-toastify'

const defaultConfiguration = (position) => {
    return {
        position,
        autoClose: 3500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }
}

export function showToast(message, position = "bottom-right") {
    toast.dark(message, defaultConfiguration(position))
}

export function showToastError500(position = "bottom-right") {
    toast.dark("Oops, ha ocurrido un error en el servidor, intente nuevamente... ", defaultConfiguration(position))
}