import './App.css'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import LogInAdmin from "./pages/LogInAdmin"
import AdminControl from './pages/AdminControl'
import Settings from './pages/Settings'
import { connect } from 'react-redux'
import authActions from './redux/actions/authActions'
import Candidate from './pages/Candidate'
import Home from './pages/Home'
import Header from './components/Header'
import Areas from './pages/Areas'
import Ranges from './pages/Ranges'
import EmailTemplate from './pages/EmailTemplate'
import Area from './pages/Area'
import Bootcamps from './pages/Bootcamps'
import EmailSettings from './pages/EmailSettings'
import EmailAreas from './pages/EmailAreas'

const App = (props) => {
    const { userLogged, logAdminLS } = props

    if (!userLogged && localStorage.getItem('token')) {
        const userInfo = JSON.parse(localStorage.getItem('userLogged'))
        const userLS = {
            ...userInfo,
            token: localStorage.getItem('token')
        }
        logAdminLS(userLS)
    }

    return (
        <BrowserRouter>
            <ToastContainer />
            <Switch>
                {!userLogged && 
                    <>
                        <Route exact path="/" component={LogInAdmin} />
                        <Redirect to="/"/>
                    </>
                }
                {userLogged &&
                    <>
                        <Header/>
                        <Route path="/home" component={Home} />
                        <Route path="/admincontrol" component={AdminControl} />
                        <Route path="/candidate/:id" component={Candidate} />
                        <Route path="/settings" component={Settings} />
                        <Route path="/areas" component={Areas}/>
                        <Route path="/area/:id" component={Area}/>
                        <Route path="/range" component={Ranges}/>
                        <Route path="/bootcamps" component={Bootcamps}/>
                        <Route path="/email/:id" component={EmailAreas}/>
                        <Route path="/email_template/:id" component={EmailTemplate}/>
                        <Route path="/email_settings/:id" component={EmailSettings}/>
                        <Redirect to="/home"/>
                    </>
                }
            </Switch>
        </BrowserRouter>
    )
}
const mapStateToProps = state => {
    return {
        userLogged: state.authReducer.userLogged
    }
}
const mapDispatchToProps = {
    logAdminLS: authActions.logAdminLS
}
export default connect(mapStateToProps, mapDispatchToProps)(App)