import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import EmailStatus from "../components/EmailStatus"
import EmailSubject from "../components/EmailSubject"
import Menu from "../components/Menu"
import ProgramLink from "../components/ProgramLink"
import emailsActions from "../redux/actions/emailsActions"
import { showToast } from "../components/helpers/toast"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom"

const EmailSettings = (props) => {
    const { getEmailById, settingsUpdate, token } = props
    const [settingsEmail, setSettingsEmail] = useState({})
    const { email_program_link, email_subject, email_test_title, email_status, email_id, bootcamp } = settingsEmail
    const idParams = props.match.params.id

    const getEmail = async () => {
        const response = await getEmailById(idParams, token)
        setSettingsEmail(response)
    }

    useEffect(() => getEmail(), [])

    var newSettings = {}

    const settingUpdate = async (email_id, setting_name, setting_value) => {
        if (setting_value !== '' && setting_value !== undefined) {
            const email = await getEmailById(email_id, token)
            var newSettings = {
                ...email,
                [setting_name]: setting_value
            }
            settingsUpdate(newSettings, token)
            setSettingsEmail(newSettings)
        } else {
            showToast('El valor es incorrecto, intente nuevamente')
        }
    }


    return (
        <div style={{ backgroundImage: 'url("/assets/background.svg")' }} className="logInBackground">
            <div className="containerAdminControl">
                <Menu />
                <div style={{margin:'4vh 0 0 4vh'}}>
                   <Breadcrumbs aria-label="breadcrumb" style={{color:'white'}}>
                      <Link to='/settings' color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                        Configuraciones
                      </Link>
                      <Link to='/bootcamps' color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                        Bootcamps
                      </Link>
                      <Link to={`/email/${idParams}`} color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                        {settingsEmail?.bootcamp}
                      </Link>
                      <p color="inherit" style={{color :'white'}}>Email Configuraciones</p>
                   </Breadcrumbs>
                </div>
                <div className="containerSettings">
                    <div className="containerSettingsInfo">
                        <h1>Configuraciones</h1>
                        <div className="containerSettingsEmail">
                            <ProgramLink email_id={idParams} email_program_link={email_program_link} settingUpdate={settingUpdate}/>
                            <EmailSubject email_id={idParams} email_subject={email_subject} settingUpdate={settingUpdate}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.authReducer.userLogged.token
    }
}

const mapDispatchToProps = {
    getEmailById: emailsActions.getEmailById,
    settingsUpdate: emailsActions.settingsUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSettings)