import Tables from '../components/Tables'
import Menu from '../components/Menu'
import { IoSearch } from 'react-icons/io5'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import candidatesActions from '../redux/actions/candidatesActions'
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import TextField from '@material-ui/core/TextField';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createTheme } from '@material-ui/core'
import { Today } from '@material-ui/icons';
import { addDays, subDays } from 'date-fns'
import { useLocation } from 'react-router-dom'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

export const customTheme = createTheme({
    overrides: {
        MuiPickersDay: {
            day: {
                color: "black !important",
                fontFamily: "\"Do Hyeon\", sans-serif",
                backgroundColor: "white",
            },
            
            // container:{
            //     backgroundColor:"black"
            // },
            daySelected: {
                backgroundColor: "#0100FB !important",
                color:"white !important"
            },
            dayDisabled: {
                color: "light-gray",
            },
            current: {
                color: "#0100FB !important",
            },
        },
        MuiInput: {
            root: 'white',
            underline: {
                borderBottom: '0.5px solid white',
                '&&:hover::before': {
                    borderBottom: 'white !important',
                  },
                  '&&:hover::after': {
                    borderBottom: 'white !important',          // String should be terminated
                  },
            },

        },
        MuiFormLabel: {
            root: {
                color: 'white',
                fontFamily: 'Poppins'
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                color: 'black !important'
            }
        },
        MuiInputBase: {
            color: 'white',
            label: {
                color: 'white',
            },
            root: {
                color: 'white !important'
            },
            input: {
              color: 'white',
              colorSecondary: 'white'
            },
        },
        MuiSelect: {
            root: {
                color: 'white !important'
            },
            iconButton: {
                color: 'white !important'
            }
        }
    },
})

const AdminControl = (props) => {
    const { candidates, getResultsTest, token } = props
    const [lateralMenu, setLateralMenu] = useState(false)

    const location = useLocation()

    const filterCandidates = location?.state?.filter?.filterCandidates
    const filterStartDate = location?.state?.filter?.startDate
    const filterEndDate = location?.state?.filter?.endDate
    const filterName = location?.state?.filter?.name
    const filterStatus = location?.state?.filter?.status

    const [candidatesFiltered, setCandidatesFiltered] = useState([])
    const [filter, setFilter] = useState()
    const [nameFilter, setNameFilter] = useState(filterName === undefined ? "" : filterName)
    const [statusFilter, setStatusFilter] = useState(filterStatus === undefined ? "TODOS" : filterStatus)
    const [selectedStart, setSelectedStart] = useState(filterStartDate === undefined ? new Date('01/01/2021') : filterStartDate);
    const [selectedEnd, setSelectedEnd] = useState(filterEndDate === undefined ? new Date() : filterEndDate);

    const endDateFormatted = new Date(selectedEnd)
    endDateFormatted.setDate(endDateFormatted.getDate())

    const startDateFormatted = new Date(selectedStart)
    startDateFormatted.setDate(startDateFormatted.getDate() -1)

    const handleStartChange = (date) => {
        setSelectedStart(date);
        if (nameFilter !== undefined && nameFilter !== "" && statusFilter !== "TODOS") {
            const candidatesStatusFilter = candidates.filter(candidate => (
                (candidate.candidate_evalart_status).toLowerCase().indexOf(statusFilter?.trim().toLowerCase()) >= 0
            ))   
            const candidatesFilter = candidatesStatusFilter.filter(candidate => (
                (candidate.firstname + " " + candidate.lastname).toLowerCase().indexOf(nameFilter.trim().toLowerCase()) >= 0
            ))        
            const dateFilter = candidatesFilter.filter(candidate => {
                if (isAfter(new Date(selectedEnd), new Date(candidate.form_date_responded)) && isBefore(subDays(date, 1), new Date(candidate.form_date_responded))) {
                    return candidate
                }
            })
            setFilter(dateFilter)
            setCandidatesFiltered(dateFilter)
        } else if (nameFilter !== undefined && nameFilter !== "" && statusFilter === "TODOS") {
            const candidatesFilter = candidates.filter(candidate => (
                (candidate.firstname + " " + candidate.lastname).toLowerCase().indexOf(nameFilter.trim().toLowerCase()) >= 0
            ))        
            const dateFilter = candidatesFilter.filter(candidate => {
                if (isAfter(new Date(selectedEnd), new Date(candidate.form_date_responded)) && isBefore(subDays(date, 1), new Date(candidate.form_date_responded))) {
                    return candidate
                }
            })
            setFilter(dateFilter)
            setCandidatesFiltered(dateFilter)
        
        } else if (nameFilter === "" && statusFilter === "TODOS") {
            const candidatesFilter = candidates.filter(candidate => {
                if (isAfter(new Date(selectedEnd), new Date(candidate.form_date_responded)) && isBefore(subDays(date, 1), new Date(candidate.form_date_responded))) {
                    return candidate
                }
            })
            setFilter(candidatesFilter)
            setCandidatesFiltered(candidatesFilter)
        } else {
            const candidatesStatusFilter = candidates.filter(candidate => (
                (candidate.candidate_evalart_status).toLowerCase().indexOf(statusFilter?.trim().toLowerCase()) >= 0
            ))   
            const candidatesFilter = candidatesStatusFilter.filter(candidate => {
                if (isAfter(new Date(selectedEnd), new Date(candidate.form_date_responded)) && isBefore(subDays(date, 1), new Date(candidate.form_date_responded))) {
                    return candidate
                }
            })
            setFilter(candidatesFilter)
            setCandidatesFiltered(candidatesFilter)
        }
    };

    const handleEndChange = (date) => {
        setSelectedEnd(date);
        if (nameFilter !== undefined && nameFilter !== "" && statusFilter !== "TODOS") {
            const candidatesStatusFilter = candidates.filter(candidate => (
                (candidate.candidate_evalart_status).toLowerCase().indexOf(statusFilter?.trim().toLowerCase()) >= 0
            ))   
            const candidatesFilter = candidatesStatusFilter.filter(candidate => (
                (candidate.firstname + " " + candidate.lastname).toLowerCase().indexOf(nameFilter.trim().toLowerCase()) >= 0
            ))  
            const dateFilter = candidatesFilter.filter(candidate => {
                if (isAfter(date, new Date(candidate.form_date_responded)) && isBefore(subDays(selectedStart, 1), new Date(candidate.form_date_responded))) {
                    return candidate
                }
            })
            setFilter(dateFilter)
            setCandidatesFiltered(dateFilter)
        } else if (nameFilter !== undefined && nameFilter !== "" && statusFilter === "TODOS") {
            const candidatesFilter = candidates.filter(candidate => (
                (candidate.firstname + " " + candidate.lastname).toLowerCase().indexOf(nameFilter.trim().toLowerCase()) >= 0
            ))        
            const dateFilter = candidatesFilter.filter(candidate => {
                if (isAfter(date, new Date(candidate.form_date_responded)) && isBefore(subDays(selectedStart, 1), new Date(candidate.form_date_responded))) {
                    return candidate
                }
            })
            setFilter(dateFilter)
            setCandidatesFiltered(dateFilter)
        } else if (nameFilter === "" && statusFilter === "TODOS") {
            const candidatesFilter = candidates.filter(candidate => {
                if (isAfter(date, new Date(candidate.form_date_responded)) && isBefore(subDays(selectedStart, 1), new Date(candidate.form_date_responded))) {
                    return candidate
                }
            })
            setFilter(candidatesFilter)
            setCandidatesFiltered(candidatesFilter)
        } else {
            const candidatesStatusFilter = candidates.filter(candidate => (
                (candidate.candidate_evalart_status).toLowerCase().indexOf(statusFilter?.trim().toLowerCase()) >= 0
            ))   
            const candidatesFilter = candidatesStatusFilter.filter(candidate => {
                if (isAfter(date, new Date(candidate.form_date_responded)) && isBefore(subDays(selectedStart, 1), new Date(candidate.form_date_responded))) {
                    return candidate
                }
            })
            setFilter(candidatesFilter)
            setCandidatesFiltered(candidatesFilter)
        }
    }

    useEffect(() => filterCandidates === undefined ? setCandidatesFiltered(candidates) : setCandidatesFiltered(filterCandidates), [candidates])

    const tableFilters = (status, name) => {
        setStatusFilter(status)
        if (name.target !== undefined && status !== "TODOS") {
            const candidatesStatusFilter = candidates.filter(candidate => (
                (candidate.candidate_evalart_status).toLowerCase().indexOf(statusFilter?.trim().toLowerCase()) >= 0
            ))   
            const candidatesFilter = candidatesStatusFilter.filter(candidate => (
                (candidate.firstname + " " + candidate.lastname).toLowerCase().indexOf(name.target.value.trim().toLowerCase()) >= 0
            ))
            const dateFilter = candidatesFilter.filter(c => {
                if (isAfter(new Date(selectedEnd), new Date(c.form_date_responded)) && isBefore(subDays(selectedStart, 1), new Date(c.form_date_responded))) {
                    return c
                }
            })
            setCandidatesFiltered(
                dateFilter
            )
            setFilter(dateFilter)
            setNameFilter(name.target.value)
        } else if (nameFilter !== undefined && status !== "TODOS") {
            const candidatesStatusFilter = candidates.filter(candidate => (
                (candidate.candidate_evalart_status).toLowerCase().indexOf(status?.trim().toLowerCase()) >=0
            ))   
            const candidatesFilter = candidatesStatusFilter.filter(candidate => (
                (candidate.firstname + " " + candidate.lastname).toLowerCase().indexOf(nameFilter.trim().toLowerCase()) >= 0
            ))
            const dateFilter = candidatesFilter.filter(c => {
                if (isAfter(new Date(selectedEnd), new Date(c.form_date_responded)) && isBefore(subDays(selectedStart, 1), new Date(c.form_date_responded))) {
                    return c
                }
            })
            setCandidatesFiltered(
                dateFilter
            )
            setFilter(dateFilter)
            setNameFilter(nameFilter)
        } else if (name.target !== undefined && name.target.value !== "" && status === "TODOS") {
            const candidatesFilter = candidates.filter(candidate => (
                (candidate.firstname + " " + candidate.lastname).toLowerCase().indexOf(name.target.value.trim().toLowerCase()) >= 0
            ))
            const dateFilter = candidatesFilter.filter(c => {
                if (isAfter(new Date(selectedEnd), new Date(c.form_date_responded)) && isBefore(subDays(selectedStart, 1), new Date(c.form_date_responded))) {
                    return c
                }
            })
            setCandidatesFiltered(
                dateFilter
            )
            setFilter(dateFilter)
            setNameFilter(name.target.value)
        } else if ((name?.target?.value === "" || nameFilter === undefined || nameFilter === "") && status === "TODOS") {
            const dateFilter = candidates.filter(c => {
                if (isAfter(new Date(selectedEnd), new Date(c.form_date_responded)) && isBefore(subDays(selectedStart, 1), new Date(c.form_date_responded))) {
                    return c
                }
            })
            setCandidatesFiltered(
                dateFilter
            )
            setFilter(dateFilter)
            setNameFilter("")
        } else if (nameFilter !== undefined && nameFilter !== "" && status === "TODOS") {
            const candidatesFilter = candidates.filter(candidate => (
                (candidate.firstname + " " + candidate.lastname).toLowerCase().indexOf(nameFilter.trim().toLowerCase()) >= 0
            ))
            const dateFilter = candidatesFilter.filter(c => {
                if (isAfter(new Date(selectedEnd), new Date(c.form_date_responded)) && isBefore(subDays(selectedStart, 1), new Date(c.form_date_responded))) {
                    return c
                }
            })
            setCandidatesFiltered(
                dateFilter
            )
            setFilter(dateFilter)
            setNameFilter(nameFilter)
        }
    }

    const updateData = async () => {
        const response = await getResultsTest('B2C', token)
        setSelectedStart(new Date('01/01/2021'))
        setSelectedEnd(new Date())
        setFilter(candidates)
        setNameFilter("")
        setStatusFilter("TODOS")
        setCandidatesFiltered(response)
    }

    return (
        <div style={{ backgroundImage: 'url("./assets/background.svg")' }} className="logInBackground">
            <div className="containerAdminControl">
                <Menu />
                <div className="containerAdminControInfo">
                    <div className="adminControlInfo">
                        <div className="filters">
                            <div className="tableName">
                                <h3>Tabla de candidatos</h3>
                                <span className="material-icons-round iconRefresh" onClick={() => updateData()}>cached</span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', flexDirection:'column', alignItems:'center'}}>
                                <p style={{color: 'white'}}>Elegí el periodo</p>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <MuiThemeProvider theme={customTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justifyContent="space-around">
                                        <KeyboardDatePicker
                                          variant="inline"
                                          format="yyyy/MM/dd"
                                          margin="normal"
                                          id="date-picker-inline"
                                          label="Desde"
                                          autoOk={true}
                                          value={selectedStart}
                                          onChange={handleStartChange}
                                          style={{marginRight: '1vh'}}
                                          keyboardIcon={<Today style={{color: 'white'}}/>}
                                          openTo="month"
                                          views={["month", "date"]}
                                        />
                                        <KeyboardDatePicker
                                          variant="inline"
                                          format="yyyy/MM/dd"
                                          margin="normal"
                                          id="date-picker-inline"
                                          label="Hasta"
                                          autoOk={true}
                                          value={selectedEnd}
                                          onChange={handleEndChange}
                                          style={{marginRight: '1vh'}}
                                          keyboardIcon={<Today style={{color: 'white'}}/>}
                                          openTo="month"
                                          views={["month", "date"]}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                </MuiThemeProvider>
                                </div>
                            </div>
                            <div style={{padding: '0vh 1vh'}}>
                            <p style={{color: 'white', margin: '1.7vh 0vh'}}>Filtro por nombre</p>
                            <TextField style={{width: '30vh'}} id="standard-basic" defaultValue={filterName !== undefined ? filterName : ""} value={nameFilter} onChange={(e) => tableFilters(statusFilter, e)} />
                            </div>
                            <div    style={{padding: '0vh 1vh'}}>
                            <p style={{color: 'white', margin: '1.7vh 0vh'}}>Filtro por status</p>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                              defaultValue={filterStatus}
                              value={statusFilter}
                              label="Age"
                              variant="standard"
                              style={{width: '30vh', borderBottomColor: 'white', padding:'0vh 1vh'}}
                              onChange={(e) => tableFilters(e?.target?.value, nameFilter)}
                            >
                              <MenuItem value={"TODOS"}>Todos</MenuItem>
                              <MenuItem value={"INICIAL"}>Inicial</MenuItem>
                              <MenuItem value={"RECURSION"}>Recursión</MenuItem>
                            </Select>
                            </div>
                        </div>
                        <Tables candidatesFiltered={candidatesFiltered?.length < 1 ? undefined : candidatesFiltered} filterCandidates={filter !== undefined ? filter : filterCandidates} startDate={selectedStart} endDate={selectedEnd} name={nameFilter} status={statusFilter}/>
                    </div>

                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        candidates: state.candidatesReducer.candidates,
        token: state.authReducer.userLogged.token
    }
}
const mapDispatchToProps = {
    getResultsTest: candidatesActions.getResultsTest,
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminControl)