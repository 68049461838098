import { useState } from "react"

const ProgramLink = (props) => {
    const { settingUpdate, email_id, email_program_link } = props
    const [newValue, setNewValue] = useState(email_program_link)
    const [editSetting, setEditSetting] = useState(true)

    return (
        <div className="containerScore">
            <div className="programContainer">
                <h2>Link de Programa</h2>
                {editSetting &&
                    <div className="containerSettingValue">
                        <span className="settingValueProgram">{email_program_link}</span>
                        <span onClick={() => setEditSetting(!editSetting)} className="material-icons-round iconEdit">edit</span>
                    </div>
                }
                {!editSetting &&
                    <div className="containerInputEdit">
                        <input className="inputEditSettingsLink" type="text" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
                        <div className="containerButtonsEdit">
                            <span onClick={() => { settingUpdate(email_id, 'email_program_link', newValue); setEditSetting(!editSetting) }} className="material-icons-round iconSendEdit">send</span>
                            <span onClick={() => setEditSetting(!editSetting)} className="material-icons-round iconEdit">close</span>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default ProgramLink