import { useEffect } from "react"
import { connect } from "react-redux"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom"
import Menu from "../components/Menu"
import emailsActions from "../redux/actions/emailsActions"

const Bootcamps = (props) => {
    const { emails, getAllEmails, token } = props
    
    useEffect(() => { getAllEmails(token) }, [])

    return (
        <div style={{ backgroundImage: 'url("./assets/background.svg")' }} className="logInBackground">
            <div className="containerAdminControl">
                <Menu />
                <div style={{margin:'4vh 0 0 4vh'}}>
                <Breadcrumbs aria-label="breadcrumb" style={{color:'white'}}>
                  <Link to='/settings' color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                    Configuraciones
                  </Link>
                  <p color="inherit" style={{color:'white'}}>Bootcamps</p>
                </Breadcrumbs>
                </div>
                <div className="containerSettings">
                    <div className="containerSettingsInfo">
                        <h1 style={{marginTop:'-0.2vh'}}>Bootcamps</h1>
                        <div className="containerBootcamps">
                            {emails?.success !== false && emails.map((email, index) =>
                                <Link key={index} to={`/email/${email.email_id}`} className="linkAreas">
                                    <h2>{email.bootcamp}</h2>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        emails: state.emailsReducer.emails,
        token: state.authReducer.userLogged.token
    }
}
const mapDispatchToProps = {
    getAllEmails: emailsActions.getAllEmails
}
export default connect(mapStateToProps, mapDispatchToProps)(Bootcamps)