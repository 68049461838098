import { useState } from "react"

const ScoreAreaMin = (props) => {
    const { area, updateMinScore } = props
    const { score_setting_id, max_score_ponderation } = area
    const [newValue, setNewValue] = useState("")
    const [editSetting, setEditSetting] = useState(true)

    return (
        <div className="containerScore">
            <h2>Mínimo porcentaje área</h2>
            {editSetting &&
                <div className="containerSettingValue">
                    <span className="settingValue">{max_score_ponderation} %</span>
                    <span onClick={() => setEditSetting(!editSetting)} className="material-icons-round iconEdit">edit</span>
                </div>
            }
            {!editSetting &&
                <>
                    <div className="containerInputEdit">
                        <input className="inputEditSettings" type="text" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
                        <div className="containerButtonsEdit">
                            <span onClick={() => { updateMinScore(score_setting_id, newValue); setEditSetting(!editSetting) }} className="material-icons-round iconSendEdit">send</span>
                            <span onClick={() => setEditSetting(!editSetting)} className="material-icons-round iconEdit">close</span>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
export default ScoreAreaMin