const initialState = {
    candidates: [],
}

const candidatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'RESULTS_TEST':
            return {
                ...state,
                candidates: action.payload,
            }   
        default:
            return state
    }
}
export default candidatesReducer