import { useState } from "react"
import { connect } from "react-redux"
import settingsActions from "../redux/actions/settingsActions"
import { showToast } from "./helpers/toast"

const Range = (props) => {
    const {range, updateRange} = props
    const {result_setting_name, result_setting_value, result_setting_id} = range
    const [editSetting, setEditSetting] = useState(true)
    const [newValue, setNewValue] = useState("")

    return(
        <div className="containerScore">
            <h2>{result_setting_name === "dudoso_min" ? "Puntaje mínimo dudoso" : "Puntaje mínimo aprobado"}</h2>
            {editSetting &&
                <div className="containerSettingValue">
                    <span className="settingValue">{result_setting_value}</span>
                    <span onClick={() => setEditSetting(!editSetting)} className="material-icons-round iconEdit">edit</span>
                </div>
            }
            {!editSetting &&
                <div className="containerInputEdit">
                    <input className="inputEditSettings" type="text" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
                    <div className="containerButtonsEdit">
                        <span onClick={() => { updateRange(result_setting_id, newValue); setEditSetting(!editSetting) }} className="material-icons-round iconSendEdit">send</span>
                        <span onClick={() => setEditSetting(!editSetting)} className="material-icons-round iconEdit">close</span>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.authReducer.userLogged.token
    }
}

const mapDispatchToProps = {
    updateResultsRange: settingsActions.updateResultsRange
}
export default connect(mapStateToProps, mapDispatchToProps)(Range)