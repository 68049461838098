import Menu from '../components/Menu'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import emailsActions from "../redux/actions/emailsActions"
import ContentEditable from "react-contenteditable"
import sanitizeHtml from "sanitize-html"
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom"

const EmailTemplate = (props) => {
   const { getEmailById, updateContent, currentEmail, token } = props
   const { email_content_one, email_content_two, email_program_link, email_id } = currentEmail
   const [contentEmailOne, setContentEmailOne] = useState(email_content_one)
   const [contentEmailTwo, setContentEmailTwo] = useState(email_content_two)
   const [editable, setEditable] = useState(true)
   const [contentOne, setContentOne] = useState(email_content_one)
   const [contentTwo, setContentTwo] = useState(email_content_two)
   const idParams = props.match.params.id
   let name = "Nombre"

   useEffect(() => {
      getEmailById(idParams, token)
   }, [])

   const EditButton = (props) => {
      return (
         <button
            key={props.cmd}
            onMouseDown={evt => {
               evt.preventDefault()
               document.execCommand(props.cmd, false, props.arg)
            }}
            className="buttonsActionsEdit"
         >
            {props.name || props.cmd}
         </button>
      );
   }

   const sanitizeConf = {
      allowedTags: ["b", "i", "em", "strong", "a", "p", "h1"],
      allowedAttributes: { a: ["href"] }
   }

   const editContentOne = async (email_id, content_name, content_value) => { 
      if (content_value !== undefined) {
         const response = await getEmailById(email_id, token)
         var contentEmail1 = { 
            ...response,
            [content_name]: content_value
         }
         updateContent(contentEmail1, token)
      }
   } 

   const editContentTwo = async (email_id, content_name, content_value) => { 
      if (content_value !== undefined) {
         const response = await getEmailById(email_id, token)
         var contentEmail2 = { 
            ...response,
            [content_name]: content_value
         }
         updateContent(contentEmail2, token)
      }
   } 

   const sanitizeOne = () => {
      setContentEmailOne(sanitizeHtml(contentEmailOne, sanitizeConf))
   }
   const sanitizeTwo = () => {
      setContentEmailTwo(sanitizeHtml(contentEmailTwo, sanitizeConf))
   }


//😀
   return (

      <div style={{ backgroundImage: 'url("/assets/background.svg")' }} className="logInBackground">
         <div className="containerAdminControl">
            <Menu />
            <div style={{margin:'4vh 0 0 4vh'}}>
               <Breadcrumbs aria-label="breadcrumb" style={{color:'white'}}>
                  <Link to='/settings' color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                    Configuraciones
                  </Link>
                  <Link to='/bootcamps' color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                    Bootcamps
                  </Link>
                  <Link to={`/email/${idParams}`} color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                        {currentEmail?.bootcamp}
                  </Link>
                  <p color="inherit" style={{color :'white'}}>Email Template</p>
               </Breadcrumbs>
            </div>
            <div className="containerSettingsInfo">
               <h1>Template Email {currentEmail.bootcamp}</h1>
               <div className="containerMail">
                  <div style={{ backgroundImage: 'url("/assets/logoMail.png")' }} className="logoMail"></div>
                  <div className="headerMail">
                     <p>{name}, te damos la bienvenida al Desafío MindHub</p>
                  </div>
                  <div className="containerMailInfo">
                     <div>
                        <h2>Estas cerca de poder iniciar tu carrera en desarrollo Full Stack <img data-emoji="😎" className="an1" alt="😎" aria-label="😎" src="https://fonts.gstatic.com/s/e/notoemoji/13.1.1/1f60e/72.png" loading="lazy"></img></h2>
                        <div className="containerEditableContent">
                           <div>
                              <ContentEditable
                                 className="editable"
                                 tagName="pre"
                                 html={contentOne === undefined ? email_content_one : contentOne}
                                 disabled={!editable}
                                 onChange={(e) => setContentOne(e.target.value)}
                                 onBlur={sanitizeOne}
                              />
                           </div>
                        </div>
                        <div className="buttons">
                           <EditButton cmd="italic" />
                           <EditButton cmd="bold" />
                           <EditButton
                           cmd="createLink"
                           arg={email_program_link}
                           name="program link"
                        />
                           <button onClick={() => editContentOne(email_id, 'email_content_one', contentOne)} className="buttonsActionsEdit">guardar cambios</button>
                        </div>
                     </div>
                     <div className="linkDesafio">Completa el Desafío MindHub</div>
                     <div className="containerEditableContent">
                        <div>
                           <ContentEditable
                              className="editable"
                              tagName="pre"
                              html={contentTwo === undefined ? email_content_two : contentTwo}
                              disabled={!editable}
                              onChange={(e) => setContentTwo(e.target.value)}
                              onBlur={sanitizeTwo}
                           />
                        </div>
                     </div>
                     <div className="buttons">
                        <EditButton cmd="italic" />
                        <EditButton cmd="bold" />
                        <EditButton
                           cmd="createLink"
                           arg={email_program_link}
                           name="program link"
                        />
                        <button onClick={() => editContentTwo(email_id, 'email_content_two', contentTwo)} className="buttonsActionsEdit">guardar cambios</button>
                     </div>
                     <p className="bold" style={{fontSize: '3vh', textAlign: 'center', margin:'3vh'}}>Estamos muy entusiasmados de acompañarte a iniciar tu camino en la tecnología <img data-emoji="😀" className="an1" alt="😀" aria-label="😀" src="https://fonts.gstatic.com/s/e/notoemoji/13.1.1/1f600/72.png" loading="lazy"></img></p>
                     <div style={{ backgroundImage: "url('/assets/reviewsMH.jpg')" }} className="reviewsMH"></div>
                     <p className="italic">
                        Nuestras reseñas hablan por nosotros: <a href="https://hs-4081936.t.hubspotstarter-iu.net/e2t/tc/VW5yc13fWgzbW8TMcwB5td6kQW1k1H6J4vPR5-N5wYXSc5kbVVV3Zsc37CgCzcW2XMrWw8BWNltVS_s-Q7HSDXvW7c6xd81DM-3-W7jwlcK9fBjkbW4wf3Hv3d_y-lW5jQ7Gc5qMhYmVwwk3g8nQsX7W8B_7B07b6j9TW4f6y-J2n4fGBW49wg6055xd2kW8XB71W314htVN8cftdB55RbzW2lmWLG30rdF5W5dvjkv2N86d3W9j08T14skWkRW2JFp_H3HjnlQN5M8F95f35cfW8B7wF61dJ1fQW2tYq3z16byQxW8CDx3X2FV1WlW549KZm1j-gBJN5mbHkS4xpxPW8cp-Ty4KSzLxW4GyBH_7YXntxW2bN6n76mBRW8W4Wf_hP8Fl0Q3W2c7BNs64Zcr_W7vlB3n9jxZNSW6zKwmQ7DrrsHW55gk6h1g5LxdW43F7_R64yWV2W433nKM1PR440VlD7qD87GYRRW8BpnrV7Db-QmW57Z6DK7xGHnDW5M-Zg34fHzzhW5XhGYX8Y_8C7W8wM1LS2xD7y7V2yHkg65HJdrW48mwV13xCLsZW4rg9J81WHd9WW4Tfx028p1h-yW6CJGtd5BBvMMVYnDDg5wJMW337mh1" rel="noopener" target="_blank" data-saferedirecturl="https://hs-4081936.t.hubspotstarter-iu.net/e2t/tc/VW5yc13fWgzbW8TMcwB5td6kQW1k1H6J4vPR5-N5wYXSc5kbVVV3Zsc37CgCzcW2XMrWw8BWNltVS_s-Q7HSDXvW7c6xd81DM-3-W7jwlcK9fBjkbW4wf3Hv3d_y-lW5jQ7Gc5qMhYmVwwk3g8nQsX7W8B_7B07b6j9TW4f6y-J2n4fGBW49wg6055xd2kW8XB71W314htVN8cftdB55RbzW2lmWLG30rdF5W5dvjkv2N86d3W9j08T14skWkRW2JFp_H3HjnlQN5M8F95f35cfW8B7wF61dJ1fQW2tYq3z16byQxW8CDx3X2FV1WlW549KZm1j-gBJN5mbHkS4xpxPW8cp-Ty4KSzLxW4GyBH_7YXntxW2bN6n76mBRW8W4Wf_hP8Fl0Q3W2c7BNs64Zcr_W7vlB3n9jxZNSW6zKwmQ7DrrsHW55gk6h1g5LxdW43F7_R64yWV2W433nKM1PR440VlD7qD87GYRRW8BpnrV7Db-QmW57Z6DK7xGHnDW5M-Zg34fHzzhW5XhGYX8Y_8C7W8wM1LS2xD7y7V2yHkg65HJdrW48mwV13xCLsZW4rg9J81WHd9WW4Tfx028p1h-yW6CJGtd5BBvMMVYnDDg5wJMW337mh1">Google (4.8/5)</a>
                        y la calificación obtenida entre los Bootcamps más importantes del mundo en
                        <a href="https://hs-4081936.t.hubspotstarter-iu.net/e2t/tc/VW5yc13fWgzbW8TMcwB5td6kQW1k1H6J4vPR5-N5wYXRk3lGmQV1-WJV7CgHXKW60YlSh3TcyX8W5MKLr93hBW44VMWQHQ229yvlN55tdN4CYwGbN1vPWzLQxM9bW5CSrZn1V0nsZW5P1MNz66VJCFW40S3489bG-lGW4Pb94r96smJQW6FxFvr7fVlynW7WC5Nf9h_G98W4JGY374l-d-kW14BMDZ85ZrgbW77vy__2GBCkNW3kg6cx8sj9MhW1H5Crz1xDknLVJJMqY7GVHsGW5jTRQ56SNH3wN3Qvr7nfx050W2D-SSn1YFfBgW3wm9L48yhY8wN3mdt9x3XyTN367z1" rel="noopener" target="_blank" data-saferedirecturl="https://hs-4081936.t.hubspotstarter-iu.net/e2t/tc/VW5yc13fWgzbW8TMcwB5td6kQW1k1H6J4vPR5-N5wYXRk3lGmQV1-WJV7CgHXKW60YlSh3TcyX8W5MKLr93hBW44VMWQHQ229yvlN55tdN4CYwGbN1vPWzLQxM9bW5CSrZn1V0nsZW5P1MNz66VJCFW40S3489bG-lGW4Pb94r96smJQW6FxFvr7fVlynW7WC5Nf9h_G98W4JGY374l-d-kW14BMDZ85ZrgbW77vy__2GBCkNW3kg6cx8sj9MhW1H5Crz1xDknLVJJMqY7GVHsGW5jTRQ56SNH3wN3Qvr7nfx050W2D-SSn1YFfBgW3wm9L48yhY8wN3mdt9x3XyTN367z1">Switch-Up (5/5)</a>.
                        y en <a href="https://hs-4081936.t.hubspotstarter-iu.net/e2t/tc/VW5yc13fWgzbW8TMcwB5td6kQW1k1H6J4vPR5-N5wYXRX3lGnpV1-WJV7Cg-bqW7t6zc06Qj1hKW2y1n558wrkHDW2534f_5YV-xtW2gWhJd7zTy2ZW1QRgFC8Qgnb0VC5v_x7f8knfW7RVsnp5QCY4SW2Pm6Cg2cdN9QW1LSxQx128wkhW5_V6J41ps8MRW10MN8R4vXQGcW1DNw994s0YvmW2jw3_P4f8kzFTVvvd7x5N30W7mh8RH2cZ2gYW7TfKY94NcBRrN8m3yRyn3GFGW1HBGRL32qqKsW3vLbtg92Mn4nW17LPFf4QgStlW1WKJNr53vHsrW4hv_Q54mFlp4W7TtBHJ13kCGjW2shMtF4bKpWfW39Q3mn1zRf5sW4JqHLN488KDG34JG1" rel="noopener" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://hs-4081936.t.hubspotstarter-iu.net/e2t/tc/VW5yc13fWgzbW8TMcwB5td6kQW1k1H6J4vPR5-N5wYXRX3lGnpV1-WJV7Cg-bqW7t6zc06Qj1hKW2y1n558wrkHDW2534f_5YV-xtW2gWhJd7zTy2ZW1QRgFC8Qgnb0VC5v_x7f8knfW7RVsnp5QCY4SW2Pm6Cg2cdN9QW1LSxQx128wkhW5_V6J41ps8MRW10MN8R4vXQGcW1DNw994s0YvmW2jw3_P4f8kzFTVvvd7x5N30W7mh8RH2cZ2gYW7TfKY94NcBRrN8m3yRyn3GFGW1HBGRL32qqKsW3vLbtg92Mn4nW17LPFf4QgStlW1WKJNr53vHsrW4hv_Q54mFlp4W7TtBHJ13kCGjW2shMtF4bKpWfW39Q3mn1zRf5sW4JqHLN488KDG34JG1&amp;source=gmail&amp;ust=1628267361671000&amp;usg=AFQjCNHUbUNKThL5wcoReOVHLEllfog4KA">CourseReport (5/5)</a>.
                     </p>
                     <div className="footerMail">
                        <p className="bold">¡Mucha suerte! <img data-emoji="🍀" className="an1" alt="🍀" aria-label="🍀" src="https://fonts.gstatic.com/s/e/notoemoji/13.1.1/1f340/72.png" loading="lazy"></img></p>
                        <p className="bold">Quedamos a tu disposición ante cualquier consulta</p>
                        <p className="bold">Team MindHub</p>
                     </div>
                  </div>
                  <div className="containerContact">
                     <span>MINDHUB, Argentina, Chile, Colombia, virtualmente en el resto de Latam y USA / Tel: +54 11 2496 6463 /+56 2 2979 7873</span>
                  </div>
               </div>
            </div>
         </div>
      </div>

   )
}

const mapStateToProps = state => {
   return {
      emails: state.emailsReducer.emails,
      currentEmail: state.emailsReducer.currentEmail,
      token: state.authReducer.userLogged.token
   }
}

const mapDispatchToProps = {
   getEmailById: emailsActions.getEmailById,
   updateContent: emailsActions.updateContent
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate)
