import Menu from '../components/Menu'
import { Link } from 'react-router-dom'

const Settings = () => {
    return (
        <div style={{ backgroundImage: 'url("./assets/background.svg")' }} className="logInBackground">
            <div className="containerAdminControl">
                <Menu />
                <div className="containerSettings">
                    <div className="containerSettingsInfo">
                        <h1>¿Qué deseas modificar?</h1>
                        <div className="containerAreas">
                            <Link to="/areas" className="linkAreas">
                                <h2>Áreas</h2>
                            </Link>
                            <Link to="/range" className="linkAreas">
                                <h2>Rangos de aprobación</h2>
                            </Link>
                            <Link to="/bootcamps" className="linkAreas">
                                <h2>Email Desafío MindHub</h2>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Settings