import { HiMail } from 'react-icons/hi'
import { ImKey } from 'react-icons/im'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { useState } from 'react';
import { connect } from 'react-redux';
import authActions from '../redux/actions/authActions';
import Button from "@material-ui/core/Button";
import { showToast } from '../components/helpers/toast';

const LogInAdmin = (props) => {
    const { logAdmin } = props
    const [logInAdmin, setLogInAdmin] = useState({ email: '', password: '' })
    const [oculto, setOculto] = useState(true)

    const readInput = e => {
        const field = e.target.name
        const value = e.target.value
        setLogInAdmin({
            ...logInAdmin,
            [field]: value
        })
    }

    const sendForm = (e) => {
        e && e.preventDefault()
        if (Object.values(logInAdmin).some(value => value === "")) {
            showToast('Todos los campos son obligatorios')
        } else {
            logAdmin(logInAdmin)
            setLogInAdmin({ email: '', password: '' })
        }
    }

    const logInEnter = (e) => {
        if (e.key === 'Enter') {
            sendForm()
        }
    }

    return (
        <>
            <div style={{ backgroundImage: 'url("./assets/background_login2.svg")' }} className="logInBackground">
                <div className="containerLogInInfo">
                    <div style={{ backgroundImage: 'url("./assets/logoMindhub.png")' }} className="logoImage"></div>
                    <div className="containerForm">
                        <h2>Ingresa tu cuenta</h2>
                        <form autoComplete="off">
                            <span className="contenedorinput"><HiMail className="inputIcons" /> <input type="email" className="input" value={logInAdmin.email} name="email" placeholder="Email" onChange={readInput} /></span>
                            <span className="contenedorinput"><ImKey className="inputIcons" /> <input type={oculto ? "password" : "text"} className="input" value={logInAdmin.password} name="password" placeholder="Contraseña" onChange={readInput}/>
                                <div onClick={() => setOculto(!oculto)} className="containerIconEye">
                                    {oculto ? <BsEyeSlash className="inputIcons" /> : <BsEye className="inputIcons" />}
                                </div>
                            </span>
                            <button onClick={sendForm} onKeyDown={logInEnter} className="buttonLogIn">INICIAR SESIÓN</button>
                        </form>
                    </div>
                    <div style={{ backgroundImage: 'url("./assets/illustrationLogIn.png")' }} className="illustrationMH"></div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = {
    logAdmin: authActions.logAdmin
}

export default connect(null, mapDispatchToProps)(LogInAdmin)