const initialState = {
    scores: [],
    results_range: []
}

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SCORES':
            return {
                ...state,
                scores: action.payload
            }
        case 'GET_RESULTS_RANGE':
            return{
                ...state,
                results_range: action.payload
            }    
        default:
            return state
    }

}
export default settingsReducer