import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import Menu from "../components/Menu"
import candidatesActions from "../redux/actions/candidatesActions"
import LoadingAnimationTable from "../components/LoadingAnimationTable"
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom"
import { useLocation } from 'react-router-dom'

const Candidate = (props) => {
    const { candidates, getCurrentCandidate, token } = props
    const idParams = props.match.params.id
    const [candidate, setCandidate] = useState({})
    const location = useLocation()
    const filter = location?.state

    useEffect(() => setCandidate(candidates.find(candidate => candidate.candidate_id == idParams)), [])

    const currentCandidate = async () => {
        const response = await getCurrentCandidate(idParams, token)
        setCandidate(response)
    }

    if (!candidate) {
        currentCandidate()
        return <LoadingAnimationTable />
    }
    
    return (
        <div style={{ backgroundImage: 'url("/assets/background.svg")' }} className="logInBackground">
            <div className="containerAdminControl">
                <Menu />
                <Breadcrumbs aria-label="breadcrumb" style={{color:'white', margin: '4vh 0vh 0vh 4vh'}}>
                  <Link to={{ pathname: "/admincontrol", state: { filter } }} color="inherit" style={{color:'#BAFD01', textDecoration: 'none'}}>
                    Tabla
                  </Link>
                  <p color="inherit" style={{color:'white'}}>Candidato</p>
                </Breadcrumbs>
                <div className="containerAdminControInfo">
                    <div className="candidateInfo">
                        <div className="candidateProfile">
                            <h1>{candidate.firstname} {candidate.lastname}</h1>
                            <div className="containerUserData">
                                <div>
                                    <h2>Test</h2>
                                    <span>{candidate.date_responded === '' ? 'No resuelto' : candidate.date_responded}</span>
                                </div>
                                <div>
                                    <h2>Bootcamp</h2>
                                    <span>{candidate.bootcamp}</span>
                                </div>
                            </div>
                            <div className="containerScoreArea">
                                <h2>Puntaje por área</h2>
                                <div className="scores">
                                    <div>
                                        <h3>Aptitud Verbal</h3>
                                        <span>
                                            <span>{candidate.first_question_total > 0 ? candidate.first_question_total : '0'}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <h3>Aptitud Númerica</h3>
                                        <span>
                                        <span>{candidate.second_question_total > 0 ? candidate.second_question_total : '0'}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <h3>Inteligencia Espacial</h3>
                                        <span>
                                            <span>{candidate.third_question_total > 0 ? candidate.third_question_total : '0'}</span>
                                        </span>
                                    </div>
                                    <div>
                                        <h3>Razonamiento Lógico</h3>
                                        <span>
                                            <span>{candidate.fourth_question_total > 0 ? candidate.fourth_question_total : '0'}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="totalScore">
                            {candidate.total_score > 0 ? candidate.total_score : '0'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        candidates: state.candidatesReducer.candidates,
        token: state.authReducer.userLogged.token
    }
}
const mapDispatchToProps = {
    getCurrentCandidate: candidatesActions.getCurrentCandidate
}
export default connect(mapStateToProps, mapDispatchToProps)(Candidate)