import Header from "../components/Header";
import Process from "../components/Process";

const Home = () => {
    return (
        <div style={{ backgroundImage: 'url("./assets/background.svg")' }} className="logInBackground">
            <div className="containerHome">
                <Process/>
            </div>
        </div>
    )
}

export default Home